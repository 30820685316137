.App {
    text-align: center;
    padding: 0 48px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.container {
    width: 100%;
    margin: 0 auto;
}

.order {
    text-align: center;
    height: 100%;
    vertical-align: middle;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #F9F3EB;
    background-color: #F9F3EB;
    border-radius: 8px;
    transition: all .4s;
    cursor: pointer;
    padding: 8px 10px 8px 10px;
}

.footer {
    display: flex;
    padding: 20px 24px;
    border: 1px solid #C4B6A4;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    margin: 24px 0 28px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

.info {
    padding-left: 10px;
}

.flex {
    display: flex;
}


.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}