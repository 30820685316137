body {
  background: #FAF3EB;
  font-family: Lato, sans-serif;
}

@media (max-width: 768px) {
    body {
      padding: 0;
    }
  
    .event {
      margin-bottom: -2px!important;
    }
  
     button {
      font-size: 14px!important;
    }
  
     .month-label {
      font-size: 16px!important;
    }
  
     .event {
      font-size: 10px;
    }
   
     .events-grid {
       width: 100%;
       max-width: 100%;
       gap: 1px!important;
    }
     
     .hall-1,.hall-2,.hall-3,.makeup {
       font-size: 16px!important;
    }
  
     .pre-hall-1, .pre-hall-2, .pre-hall-3 {
       font-size: 14px!important; 
    }
       
     .time-label-cell {
       padding-top: 4px;
       font-size: 10px!important;
    }
}

.switchButton {
  background: #f8eee3;
  border-radius: 4px;
  border: 1.4px solid #dbccba;
  width: 40px;
  height: 40px;
  padding: 8px 6px 6px;
  transition: 0.7s;
  cursor: pointer;
}

.switchButton:hover {
  background: #C5616412;
  border: 1.4px solid #dbccba;
  transition: 0.7s;
}

.switchButton svg{
  transition: 0.7s;
  fill: #333333;
}

.switchButton:hover svg{
  fill: #C56164;
  transition: 0.7s;
}

div.hall-selector label{
  font-size: 16px;
  margin-right: 12px;
}

#hallDropdown {
  font-size: 16px;
  background: #ffffff;
  border-top: 1px solid #ffffff;
  border-right: 16px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-radius: 4px;
  outline: 1px solid #E4DCD1;
  position: relative;
  display: inline-block;
  min-width: 100px;
  width: 100%;
  max-width: 180px;
  padding: 12px;
  min-height: 44px;
  text-align: left;
  color: #1e1e1e;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.prev-week span, .next-week span {
  font-size: 24px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.month-label {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.month-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 12px;
}

.calendar {
  display: block;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  width: 100%;
  border: none;
}

.hall-selector {
  margin-bottom: 20px;
}

.days-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  border: none;
}

.day {
  padding: 5px;
  font-size: 18px;
  line-height: 24px;
}

.cell {
  border: 1.4px solid #E4DCD1;
  z-index: -1;
  min-height: 60px;
  border-radius: 8px;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 100%; /* Adjust the row height */
  width: 100%;
  height: 100%;
  gap: 8px;
}

.event {
  padding: 12px;
  margin: 0px 0px -3px 0px;
  border: 1.4px solid #E4DCD1;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  background-color: #e1e1e1;
  text-align: center;
}

.hall-1,.hall-2,.hall-3,.makeup,.activeTime {
  border-radius: 8px;
  border: 1px solid #C4B6A4;
  background: #fff;
  padding: 0;
  font-weight: 700;
  color: #726557
}

.hall-1 span, .hall-2 span, .hall-3 span, .makeup span {
  font-size: 14px;
  line-height: 1.5;
  color: #a4a4a4;
}

.pre-hall-1, .pre-hall-2, .pre-hall-3 {
  border-radius: 8px;
  /* border-top: 4px solid #8583FF;
  border-left: none;
  border-right: none;
  border-bottom: none; */
  background: repeating-linear-gradient(
  125deg,#FFFFFF,#FFFFFF 10px,#F1EDE8 10px,#F1EDE8 20px);
  /* box-shadow: 0px 4px 24px 0px rgba(167, 167, 167, 0.24); */
  color: #1F2227;
  font-weight: 500;
  font-size: 20px;
  padding: 18px 0px 0px 0px;
}

.time-event-grid {
  display: flex;
  width: 100%;
}

.time-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;
  width: 48px;
  margin-top: -30px;
}

.time-slot {
  height: 60px; /* Саме така ж висота, як і в events-grid */
  display: flex;
  align-items: center;
}

.time-label-cell {
  text-align: center;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  line-height: 24px;
  font-size: 18px;
  font-weight: 700;
  color: #726557;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #C4B6A4;
  background-color: #F9F3EB;
  border-radius: 8px;
  transition: all .4s;
  cursor: pointer;
}

.time-label-cell:hover {
  background-color: #C4B6A4;
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  height: 48px;
}

.booking {
  background-color: transparent;
  font-size: 12px;
  color: #998165;
}

.isPicked {
  background-color: #1E2127;
  color: #fff;
}

.isPicked:hover {
  background-color: #1E2127;
}

.isDisabled {
  cursor: auto;
}

.isDisabled:hover {
  background-color: #000;
}

.closeIcon {
  position: relative;
  width: 14px;
  height: 14px;
  opacity: 1;
}

.closeIcon:before, .closeIcon:after {
  position: absolute;
  left: 6px;
  content: ' ';
  height: 14px;
  width: 2px;
  background-color: #726557;
}
.closeIcon:before {
  transform: rotate(45deg);
}
.closeIcon:after {
  transform: rotate(-45deg);
}