.wrapper {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0 20px;
}

.list {
    font-weight: 700;
    margin-left: 32px;
    font-size: 18px;
    line-height: 24px;
}

.list:first-child {
    margin-left: 0;
}

.wrapper .list a {
    color: #000;
    text-decoration: none;
}

.wrapper .list a:hover {
    color: #00a8ff;
}

.wrapper .list a.active {
    color: #00a8ff;
}

.navBarWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 48px;
}

.logo {
    width: 60px;
    height: 32px;
    display: block;
    background-image: url("./images/logo.png");
}

.studio {
    padding: 8px 16px;
    border-radius: 25px;
    border: 2px solid;
    box-shadow: 0 0 0 4px #FFDFD8;
}