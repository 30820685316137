.hallSelectorWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.selectRoom {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

.rooms {
    min-width: 100px;
    width: 100%;
    max-width: 300px;
    min-height: 38px;
    margin-left: 12px;
}