.wrapperService {
    text-align: center;
    margin-top: 20px;
}

.addService {
    font-weight: 700;
    font-size: 24px;
}

.formService {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 30px auto;
    max-width: 640px;
}

.label {
    display: block;
    margin-bottom: 10px;
}

.userWrapper {
    display: flex;
    max-width: 490px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px auto;
}

.userLabel {
    display: block;
    margin-bottom: 10px;
    margin-right: 20px;
}
